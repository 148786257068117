<template>
  <div class="sctp-bg-white">
    <div class="sctp-pad15">
      <el-form class="form" :model="form" :rules="formRules" ref="form" label-width="160px">
        <el-form-item label="邮箱认证状态">
          <div v-if="email">
            {{ email }}
            <el-tag size="small" type="success">已认证</el-tag>
          </div>
          <div v-else>
            未认证
          </div>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input placeholder="请输入验证码" v-model="form.code">
            <el-button @click="sendCode('form')" :disabled="send" slot="append">
              <span v-if="!send">发送验证码</span>
              <count-down v-else @finish="onFinish" format="ss秒后重试" :time="60 * 1000"></count-down>
            </el-button>
          </el-input>
          <div ref="captcha" class="captcha"></div>
        </el-form-item>
        <el-form-item>
          <el-button @click="bindEmail('form')" v-if="email" type="primary" class="sctp-square">
            重新绑定邮箱
          </el-button>
          <el-button @click="bindEmail('form')" v-else type="primary" class="sctp-square">绑定邮箱
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {captcha, user} from "@/apis/index";
import validator from '../../../../resources/js/async-validator'

require('@ASSET/libs/jigsaw/jigsaw.min');
export default {
  name: 'bindEmail',
  components: {
    CountDown: () => import('@CMP/common/CountDown')
  },
  data() {
    return {
      form: {
        email: null,
        code: null
      },
      email: null,
      formRules: {
        email: [
          {required: true, message: '邮箱不能为空', trigger: ['blur', 'change']},
          {validator: validator.validEmail, message: '请输入正确邮箱!', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (this.email && this.email === value) {
                callback(new Error('邮箱不能与当前绑定邮箱一致'));
                return
              }
              callback()
            },
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              if (value) {
                user.checkEmailUniqueness({
                  userId: this.user.userId,
                  email: value,
                }).then(res => {
                  let {data} = res;
                  if (data) {
                    callback(new Error('邮箱已被注册'));
                  } else {
                    callback()
                  }
                })
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {len: 6, message: '验证码只能为6位', trigger: 'blur'}
        ]
      },
      send: false // 是否发送验证码
    }
  },
  methods: {
    onFinish() {
      this.send = false
    },
    getEmail() {
      this.$request.post({
        reqcode: '1050',
        reqdesc: '获取用户邮箱',
        userId: this.user.userId
      }).then(res => {
        this.email = res.data
        // this.form.email = res.data;
      })
    },
    doSendCode() {
      // 发送验证码
      this.send = true;
      captcha.sendEmailCode({
        email: this.form.email,
        type: 'BIND_EMAIL',
        userId: this.user.userId
      }).then(res => {
        this.$message.success('验证码发送成功，请注意查收!')
      }).catch(res => {
        this.send = false
      });
    },
    sendCode(formName) {
      this.$refs[formName].validateField('email', (errMsg) => {
        if (!errMsg) {
          this.initCaptcha({
            onSuccess: this.doSendCode
          });
          return true
        }
        this.$message.warning('请输入正确邮箱!');
        return false
      })
    },
    initCaptcha({onSuccess, onFail}) {
      window.jigsaw.init({
        el: this.$refs.captcha,
        onSuccess: () => {
          this.$refs.captcha.innerHTML = '';
          if (onSuccess) {
            onSuccess();
          }
        },
        onFail: () => {
          if (onFail) {
            onFail();
          }
        },
        onRefresh: () => {
        }
      })
    },
    bindEmail(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.post({
            reqcode: '1052',
            reqdesc: '绑定邮箱',
            email: this.form.email,
            code: this.form.code,
            userId: this.user.userId
          }).then(res => {
            this.$message.success('绑定成功');
            this.reset(formName)
          });
          return true
        }
        return false
      })
    },
    reset(formName) {
      this.$refs[formName].resetFields();
      this.send = false;
      this.getEmail()
    },
  },
  computed: {},
  beforeMount() {
    this.getEmail()
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .form {width: 500px;}
  .captcha {position: absolute !important;bottom: 0;right: 0;background-color: #FFF;box-shadow: 0 0 10px 1px #ccc;z-index: 1;}
  /*@formatter:on*/
</style>
