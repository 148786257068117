import { render, staticRenderFns } from "./BindEmail.vue?vue&type=template&id=051c18ae&scoped=true&"
import script from "./BindEmail.vue?vue&type=script&lang=js&"
export * from "./BindEmail.vue?vue&type=script&lang=js&"
import style0 from "./BindEmail.vue?vue&type=style&index=0&id=051c18ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051c18ae",
  null
  
)

export default component.exports